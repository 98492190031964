<template>
  <div class='risk'>
    <div class="riskTotal">
      <div id="chart"></div>
      <div id='linechart'>折线图</div>
    </div>
  </div>
</template>

<script>
import { getWeekRemind, getWeekLevel } from '@/api'
export default {
  data () {
    return {
      levelList: [],
      levelTotal: 0,
      xData: [],
      yData: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getWeekRemind().then(res => {
        const arr = res.data.level
        this.levelTotal = arr.reduce((prev, next) => {
          return prev + next.count;
        }, 0)
        arr.map(el => {
          el.percent = (el.count / this.levelTotal).toFixed(2)
        })
        this.levelList = arr
        this.drawEcharts()

      })
      getWeekLevel().then(res => {
        const { data } = res
        let xData = []
        let yData = []
        data.map(el => {
          xData.push(el.date)
        })
        xData = [...new Set(xData)].sort()
        this.xData = xData
        let echararr = [];
        data.sort((a, b) => {
          if (a.date === b.date) {
            return a.level - b.level;
          }
          return a.date > b.date ? 1 : -1
        })
        for (let i = 0; i < data.length; i++) {
          let arrindex = echararr.findIndex((item) => { return item.level === data[i].level })//获取下标
          if (arrindex == -1) {//不存在盘符数据的添加盘符数据
            echararr.push({
              level: data[i].level,
              // date: ,
              count: [data[i].count]
            });
          } else {//有盘符则往盘符数据中添加
            echararr[arrindex].count.push(data[i].count)
          }
        }

        echararr = echararr.filter(el => el.level !== 0)
        echararr.map(el => {
          yData.push(el.count)
        })
        this.yData = yData
        this.drawLine()
      })
    },
    drawEcharts () {
      const chart = this.$echarts.init(document.getElementById('chart'))

      let value = 0;
      let value1 = 0;
      let value2 = 0;
      this.levelList.map(el => {
        switch (el.level) {
          case 1:
            value = el.percent
            break;
          case 2:
            value1 = el.percent
            break;
          case 3:
            value2 = el.percent
            break;
        }
      })
      // const data = [value, value1];
      const option = {
        title: [
          {
            text: '低风险',
            x: '15%',
            y: '80%',
            textStyle: {
              fontSize: 14,
              fontWeight: '100',
              color: '#FFFFFF',
              lineHeight: 16,
              textAlign: 'center',
            },
          },
          {
            text: '中风险',
            x: '45%',
            y: '80%',
            textStyle: {
              fontSize: 14,
              fontWeight: '100',
              color: '#FFFFFF',
              lineHeight: 16,
              textAlign: 'center',
            },
          },
          {
            text: '高风险',
            x: '75%',
            y: '80%',
            textStyle: {
              fontSize: 14,
              fontWeight: '100',
              color: '#FFFFFF',
              lineHeight: 16,
              textAlign: 'center',
            },
          },
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '60%',
            center: ['20%', '45%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#44CAFF',
                  },
                  {
                    offset: 1,
                    color: '#3196FA',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: [value, value], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: 'rgba(255,255,255,0.2)',
            },
            label: {
              // textStyle: {
              fontSize: 16,
              color: '#fff',
              // }
            },
            outline: {
              // show: false
              borderDistance: 5,
              itemStyle: {
                borderWidth: 8,
                borderColor: '#3196FB',
                opacity: 0.1,
              },
            },
          },


          { //第二个球的填充
            type: 'liquidFill',
            radius: '60%',
            center: ['50%', '45%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#FACC14',
                  },
                  {
                    offset: 1,
                    color: '#9C6C09',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: [value1, value1], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: 'rgba(255,255,255,0.2)',
            },
            label: {
              // normal: {
              // textStyle: {
              fontSize: 16,
              color: '#fff',
              // }
              // },
            },
            outline: {
              // show: false
              borderDistance: 5,
              itemStyle: {
                borderWidth: 8,
                borderColor: '#FFFC20',
                opacity: 0.1,
                // borderDistance: 10
              },
            },
          },
          { //第三个球的填充
            type: 'liquidFill',
            radius: '60%',
            center: ['80%', '45%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#EF4864',
                  },
                  {
                    offset: 1,
                    color: '#982A85',
                  },
                ],
                globalCoord: false,
              },
            ],
            data: [value2, value2], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: 'rgba(255,255,255,0.20)',
            },
            label: {
              // normal: {
              // textStyle: {
              fontSize: 16,
              color: '#fff',
              // }
              // },
            },
            outline: {
              // show: false
              borderDistance: 5,
              itemStyle: {
                borderWidth: 8,
                borderColor: '#EF4864',
                opacity: 0.1
              },
            },
          },
        ],
      };
      option && chart.setOption(option)
      window.addEventListener("resize", () => {
        chart.resize();
      })

    },
    drawLine () {
      const chart = this.$echarts.init(document.getElementById('linechart'))
      const option = {
        tooltip: {
          show: false,
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#57617B'
            }
          }
        },
        // legend: {
        //   icon: 'rect',
        //   itemWidth: 14,
        //   itemHeight: 5,
        //   itemGap: 13,
        //   data: ['南宁-曼芭', '桂林-曼芭', '南宁-甲米'],
        //   right: '4%',
        //   textStyle: {
        //     fontSize: 12,
        //     color: '#F1F1F3'
        //   }
        // },
        grid: {
          left: '3%',
          right: '10%',
          bottom: '3%',
          top: '13%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#E1FFFE'
            }
          },
          name: '(日期)',
          boundaryGap: true,
          offset: 4,
          data: this.xData
        }],
        yAxis: [{
          type: 'value',
          name: '(数量)',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E1FFFE'
            }
          },
          axisLabel: {
            margin: 10,
            // textStyle: {
            fontSize: 14
            // }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#57617B'
            }
          }
        }],
        series: [{
          type: 'line',
          smooth: true,
          lineStyle: {
            color: '#00BCFF',
            width: 3
          },
          symbol: 'none',
          areaStyle: {
            // normal: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(76,246,243,0.2)'
            }, {
              offset: 0.8,
              color: 'rgba(76,246,243,0)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
            // }
          },
          itemStyle: {
            // normal: {
            color: 'rgb(137,189,27,0)'
            // }
          },
          data: this.yData[0]
        },
        {
          type: 'line',
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#F8CB41'
          },
          symbol: 'none',
          areaStyle: {
            // normal: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(248, 203, 65, 0.3)'
            }, {
              offset: 0.8,
              color: 'rgba(248, 203, 65, 0)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
            // }
          },
          itemStyle: {
            // normal: {
            color: 'rgb(0,136,212)'
            // }
          },
          data: this.yData[1]
        }, {
          type: 'line',
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#EF4864'
          },
          symbol: 'none',
          areaStyle: {
            // normal: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(239, 72, 100, 0.4)'
            }, {
              offset: 0.8,
              color: 'rgba(239, 72, 100, 0)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
            // }
          },
          itemStyle: {
            // normal: {
            color: 'rgb(219,50,51)'
            // }
          },
          data: this.yData[2]
        }
        ]
      };
      option && chart.setOption(option)
      window.addEventListener("resize", () => {
        chart.resize();
      })
    }
  }
};
</script>

<style scoped lang="scss">
.risk {
  width: 545px;
  height: 518px;
  background: url("../../../../assets/area/right_top.png") no-repeat;
  background-size: 100% 100%;
  .riskTotal {
    margin-top: 40px;
  }
  #chart {
    width: 545px;
    height: 180px;
  }
  #linechart {
    margin-top: 60px;
    width: 545px;
    height: 220px;
  }
}
</style>

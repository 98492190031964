<template>
  <div class='disinfect'>
    <div class="disinfect_content">
      <el-row class="disinfect_title">
        <el-col :span="4">项目</el-col>
        <el-col :span="8">酒店名称</el-col>
        <el-col :span="4">房间</el-col>
        <el-col :span="6">时间</el-col>
        <el-col :span="2">结果</el-col>
      </el-row>
      <div class="swiper swiper_disinfect" v-if="disinfectList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in disinfectList" class="swiper-slide" :data="JSON.stringify(el)" :key="index">
            <el-col :span="4" class="type">
              <i :style="{background:el.type === 1 ? '#F57802' : el.type === 2 ? '#00FF00' : el.type === 3 ? '#FFD400' : el.type === 4 ? '#44CAFF' : el.type === 5 ? '#6A5ACD' : 'none'}"></i>
              <span>{{el.type === 1 ? '洁具洗消' : el.type === 2 ? '布草更换' : el.type === 3 ? '杯具清洁' : el.type === 4 ? '空气监测' :el.type === 5 ? '水质监测' :''}}</span>
            </el-col>
            <el-col :span="8">{{el.hotelName}}</el-col>
            <el-col :span="4">{{el.roomId?el.roomId:'全部'}}</el-col>
            <el-col :span="6">{{$moment(el.gmtCreate).format('MM-DD HH:mm:ss')}}</el-col>
            <el-col :span="2" :style="{color:el.upstandardStatus ? '#ef4864' : '#00A80A'}">{{el.upstandardStatus ? '不合格' : '合格'}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSterilizeList } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import { environment } from '@/utils/config.js'

Swiper.use([Autoplay])
// let vm = null
export default {
  data () {
    return {
      disinfectList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      const start = this.$moment().subtract(1, 'days').format("YYYY-MM-DD")
      const stop = this.$moment().format("YYYY-MM-DD")
      getSterilizeList(start, stop).then(res => {
        const arr = [
          {
            gmtCreate: "2022-09-14 00:00:00",
            hotelName: "维也纳国际酒店(杭州博奥中心地铁站店)",
            type: 5,
            upstandardStatus: false
          },
          {
            gmtCreate: "2022-09-13 12:30:48",
            hotelName: "杭州萧山宝盛宾馆有限公司",
            type: 5,
            upstandardStatus: false
          },
          {
            gmtCreate: "2022-09-13 19:30:48",
            hotelName: "杭州萧山宝盛宾馆有限公司",
            type: 5,
            upstandardStatus: false
          },
        ]
        res.data.push(...arr)
        this.disinfectList = res.data.sort((a, b) => {
          return b.gmtCreate < a.gmtCreate ? -1 : 1
        })
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.disinfectList && this.disinfectList.length > 8) {
          new Swiper('.swiper_disinfect', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500,
            on: {
              click: (swiper) => {
                const data = JSON.parse(swiper.clickedSlide.attributes['data'].value)
                // let item = JSON.parse(this.clickedSlide.attributes["data-href"].nodeValue)
                this.checkDetail(data)
              }
            }
          })
        }
        if (this.disinfectList && this.disinfectList.length <= 8) {
          new Swiper('.swiper_disinfect', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500,
            on: {
              click: (swiper) => {
                const data = JSON.parse(swiper.clickedSlide.attributes['data'].value)
                // let item = JSON.parse(this.clickedSlide.attributes["data-href"].nodeValue)
                this.checkDetail(data)
              }
            }
          })
        }
      })
    },
    checkDetail (val) {
      const IP = environment === 'DE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      // const type = localStorage.getItem('type')
      // if (type) {
      // window.open(`http://ldiva.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // } else {
      window.open(`${IP}/app/clearlist?token=${this.$store.getters.token}&hotelName=${val.hotelName}&roomId=${val.roomId}&date=${this.$moment(val.gmtCreate).format('YYYY-MM-DD')}`)
    }
  }
};
</script>

<style scoped lang="scss">
.disinfect {
  width: 736px;
  height: 306px;
  background: url("../../../../assets/area/center_bottom.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 40px;
  box-sizing: border-box;
  .disinfect_content {
    margin-top: 60px;
    .disinfect_title {
      margin-bottom: 10px;
      .el-col {
        font-size: 16px;
        font-weight: 500;
        color: #2ea7e0;
        text-align: center;
      }
    }
    .swiper_disinfect {
      overflow: hidden;
      height: 216px;
      .swiper-slide {
        .el-col {
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          // display: flex;
          height: 100%;
          // line-height: 24px;
          // align-items: center;
          // justify-content: center;
        }
        .el-col-8 {
          width: 33.33%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .el-col-4,
        .el-col-6 {
          color: #ffd400;
        }
        .type {
          text-align: left;
          color: #fff;
          i {
            display: inline-block;
            width: 11px;
            height: 11px;
            // background: none;
            border-radius: 50%;
            margin: 0 8px;
          }
        }
        .el-col-2 {
          font-weight: 600;
        }
      }
      .swiper-slide:nth-child(2n-1) {
        height: 24px;
        line-height: 24px;
        background: rgba(68, 202, 255, 0.4);
      }
      .swiper-slide:nth-child(2n) {
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
</style>

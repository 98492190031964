<template>
  <div class='area'>
    <header-top />
    <div class="content">
      <left-top />
      <center-top />
      <right-top />
    </div>
    <div class="content">
      <left-bottom />
      <center-bottom />
      <right-bottom />
    </div>
  </div>
</template>

<script>
import HeaderTop from './components/header'
import LeftTop from './components/leftTop'
import RightTop from './components/rightTop'
import LeftBottom from './components/leftBottom'
import RightBottom from './components/rightBottom'
import CenterBottom from './components/centerBottom'
import CenterTop from './components/centerTop'
export default {
  components: { HeaderTop, LeftTop, RightTop, LeftBottom, RightBottom, CenterBottom, CenterTop },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.area {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  background: url("../../assets/city/bg.png") no-repeat;
  background-size: 100% 100%;
  .content {
    padding: 0 24px 24px;
    display: flex;
    justify-content: space-between;
  }
}
</style>

<template>
  <div class='video'>
    <!-- <div class="videobox" id="myVideoDiv" v-if="activeVideo">
      <video :src="`https://iva.terabits.cn${activeVideo}`" id="myvideoback" width="100%" height="100%" controls autoPlay muted>
      </video>
    </div> -->
    <!-- 1111 -->
    <ul>
      <li>
        <p>
          <img src="@/assets/area/cup_conformity.png" alt="">
        </p>
        <div>
          <h5>杯具洗消间合格数量</h5>
          <p>
            <span>{{totalList.qualifiedRoomCount}}</span>
            间
          </p>
        </div>
      </li>
      <li>
        <p>
          <img src="@/assets/area/sanitary_conformity.png" alt="">
        </p>
        <div>
          <h5>洁具合格数量</h5>
          <p>
            <span>{{totalList.sanitaryUpStandard}}</span>
            间
          </p>
        </div>
      </li>
      <li>
        <p>
          <img src="@/assets/area/linen_exchange.png" alt="">
        </p>
        <div>
          <h5>布草流转数量</h5>
          <p>
            <span>{{totalList.textileInSum}}</span>
            间
          </p>
        </div>
      </li>
      <li>
        <p>
          <img src="@/assets/area/linen_room.png" alt="">
        </p>
        <div>
          <h5>布草合格数量</h5>
          <p>
            <span>{{totalList.linenUpStandard}}</span>
            间
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getTotalSterilize } from '@/api'
// import videojs from 'video.js'
export default {
  data () {
    return {
      activeVideo: null,
      videoIndex: 0,
      videoList: [],
      totalList: {}
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      // getSterilizeVideo().then(res => {
      //   this.videoList = res.data
      //   this.activeVideo = res.data[0]
      //   // this.$nextTick(() => {
      //   //   this.playBack()
      //   // })

      // })
      const start = this.$moment().subtract(1, 'month').format("YYYY-MM-DD")
      const stop = this.$moment().subtract(1, 'days').format("YYYY-MM-DD")
      getTotalSterilize(start, stop).then(res => {
        this.totalList = res.data
      })
    },
    playBack () {
      let myvid = document.getElementById('myvideoback');
      // let activeVideo = 0
      let index = this.videoIndex
      // debugger
      let videoPlay = myvid.play()
      videoPlay.then(() => {
      }).catch((err) => {
        console.log(err)
        console.log("不允许自动播放")
        myvid.muted = true;
        myvid.play();
      });
      const _this = this
      myvid.addEventListener('ended', function () {
        // update the new active video index
        index += 1
        if (index === _this.videoList.length - 1) {
          index = 0
        }
        _this.activeVideo = _this.videoList[index],
          _this.videoIndex = index

        setTimeout(function () {

          myvid.play();

        }, 200);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.video {
  width: 545px;
  height: 306px;
  background: url("../../../../assets/area/disinfection_month.png") no-repeat;
  background-size: 100% 100%;
  padding: 105px 77px 0 80px;
  box-sizing: border-box;
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    li {
      display: flex;
      align-items: center;
      width: 50%;
      > p {
        margin-right: 11px;
        width: 48px;
        height: 44px;
        img {
          width: 100%;
        }
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      > div {
        h5 {
          font-size: 14px;
          color: #ffffff;
          margin-bottom: 14px;
        }
        p {
          font-size: 14px;
          color: #ffffff;
          span {
            font-size: 24px;
            font-weight: bold;
            color: #00ddff;
          }
        }
      }
    }
    li:nth-child(2n) {
      justify-content: flex-end;
    }
    li:nth-child(1),
    li:nth-child(2) {
      margin-bottom: 62px;
    }
    // .videobox {
    //   background: #0e102c;
    //   margin-top: 60px;
    //   width: 455px;
    //   height: 220px;
    //   padding: 5px;
  }
}
</style>

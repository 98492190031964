<template>
  <div class='remind'>
    <ul class="remind_total">
      <li>
        <p><img src="@/assets/area/cup.png" alt=""></p>
        <div>
          <h4>{{cupNumber}}</h4>
          <p>杯具清洗异常</p>
        </div>
      </li>
      <li>
        <p><img src="@/assets/area/sanitary.png" alt=""></p>
        <div>
          <h4>{{sanitaryNumber}}</h4>
          <p>洁具清洗异常</p>
        </div>
      </li>
      <li>
        <p><img src="@/assets/area/linen.png" alt=""></p>
        <div>
          <h4>{{linenNumber}}</h4>
          <p>布草更换异常</p>
        </div>
      </li>
      <li>
        <p><img src="@/assets/area/device.png" alt=""></p>
        <div>
          <h4>{{deviceNumber}}</h4>
          <p>设备运行异常</p>
        </div>
      </li>
    </ul>
    <div class="remind_content">
      <el-row class="remind_title">
        <el-col :span="20">酒店名称</el-col>
        <el-col :span="4">报警数量</el-col>
      </el-row>
      <div class="swiper swiper_remind" v-if="remindList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in remindList" class="swiper-slide" :key="index">
            <el-col :span="20">{{el.name}}</el-col>
            <el-col :span="4" :class="index === 0?'top1':index === 1?'top2':index === 2?'top3':''">{{el.count}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWeekRemind } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      cupNumber: 0,
      sanitaryNumber: 0,
      linenNumber: 0,
      deviceNumber: 0,
      remindList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getWeekRemind().then(res => {
        const { branch, site } = res.data
        branch.map(el => {
          switch (el.branch) {
            case 1:
              this.cupNumber = el.count
              break;
            case 2:
              this.sanitaryNumber = el.count
              break;
            case 3:
              this.linenNumber = el.count
              break;
            case 4:
              this.deviceNumber = el.count
              break;
          }
        })
        // this.remindList = site
        this.remindList = site.sort((a, b) => {
          return b.count - a.count
        })
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.remindList && this.remindList.length > 7) {
          new Swiper('.swiper_remind', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.remindList && this.remindList.length <= 7) {
          new Swiper('.swiper_remind', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.remind {
  width: 565px;
  height: 518px;
  background: url("../../../../assets/area/left_top.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 57px 0 36px;
  box-sizing: border-box;
  .remind_total {
    display: flex;
    justify-content: space-between;
    margin-top: 65px;
    li {
      width: 90px;
      height: 138px;
      background: url("../../../../assets/area/bracket.png") no-repeat;
      background-size: 100% 100%;
      > p {
        width: 100%;
        height: 63px;
        padding-top: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      div {
        width: 100%;
        text-align: center;
        h4 {
          margin-bottom: 12px;
          font-size: 28px;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 0 0 10px #facc14;
        }
        p {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
  .remind_content {
    margin-top: 70px;
    .remind_title {
      padding-left: 43px;
      font-size: 16px;
      font-weight: 500;
      color: #44caff;
      margin-bottom: 10px;
      .el-col-4 {
        text-align: center;
      }
    }
    .swiper_remind {
      overflow: hidden;
      height: 192px;
      .swiper-slide {
        padding-left: 19px;
        .el-col-4 {
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          font-weight: 700;
        }
        .top1 {
          color: #ef4864;
        }
        .top2 {
          color: #b620e0;
        }
        .top3 {
          color: #facc14;
        }
      }
      .swiper-slide:nth-child(2n-1) {
        height: 21px;
        line-height: 21px;
        color: #ffffff;
        font-size: 14px;
        background: rgba(68, 202, 255, 0.4);
      }
      .swiper-slide:nth-child(2n) {
        color: #ffffff;
        font-size: 14px;
        height: 36px;
        line-height: 36px;
      }
    }
  }
}
</style>

<template>
  <div class="cityheader">
    <div class="left">
      <i :class="`iconfont ${icon}`"></i>
      <div class="weather">
        <p>{{leftName}}</p>
        <p><span>{{temperature}}℃</span> <span>{{weather}}</span></p>
      </div>
      <p class="line"></p>
      <div class="time">
        <span>
          <img src="@/assets/city/time.png" alt="">
        </span>
        <p class="word">时间</p>
        <p>
          <span>{{$moment(time).format('HH:mm')}}</span>
          <span>{{$moment().format('MM-DD')}}</span>
        </p>
      </div>
    </div>
    <div class="center">
      <!-- <p>{{title}} “住宿卫生在线”</p> -->
      <p>
        <img src="@/assets/area/title.png" alt="">
      </p>
    </div>
    <div class="right">
      <p>{{name}}</p>
      <div class="goback" @click="goback">
        <i></i>
        <span>返回平台</span>
      </div>
    </div>
  </div>
</template>

<script>
var interval
import AMap from 'AMap'
import { environment } from '@/utils/config.js'
import { getAreaMsg } from '@/api'
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      time: this.$moment(),
      leftName: '',
      temperature: 0,
      weather: '晴',
      icon: null,
      name: ''
      // title: '浙江省'
    };
  },
  created () {
    interval = setInterval(() => this.time = new Date(), 1000);

  },
  mounted () {
    this.init()
  },
  destroyed () {
    clearInterval(interval)
  },
  methods: {
    ...mapMutations(['SET_NAME']),
    init () {
      getAreaMsg().then(res => {
        this.initWeather(res.data.area)
        this.name = res.data.name
        this.SET_NAME(res.data.name)
      })
    },
    // 获取天气
    initWeather (area) {
      // debugger
      const _this = this
      AMap.plugin('AMap.Weather', function () {
        //创建天气查询实例
        var weather = new AMap.Weather();
        //执行实时天气信息查询
        weather.getLive(area, function (err, data) {
          let iconfont = 'icon-tianqiyubao'
          if (!err) {
            if (data.weather.indexOf('阴') !== -1) iconfont = 'icon-yin'
            if (data.weather.indexOf('晴') !== -1) iconfont = 'icon-qing'
            if (data.weather.indexOf('云') !== -1) iconfont = 'icon-duoyun'
            if (data.weather.indexOf('雨') !== -1) iconfont = 'icon-yu'
            if (data.weather.indexOf('雪') !== -1) iconfont = 'icon-zhenxue'
            if (data.weather.indexOf('风') !== -1) iconfont = 'icon-feng'
            if (data.weather.indexOf('霾') !== -1) iconfont = 'icon-mai'
            if (data.weather.indexOf('雾') !== -1) iconfont = 'icon-wu'
            if (data.weather.indexOf('沙') !== -1) iconfont = 'icon-qiangshachenbao'
            // document.title = `${data.city}“住宿卫生在线”`
            // if (data.city === '温州市') {
            //   _this.title = '温州市'
            // } else {
            //   _this.title = data.city
            // }
            _this.icon = iconfont,
              _this.temperature = data.temperature,
              _this.weather = data.weather,
              _this.leftName = data.city
          }

        });
      });
    },
    goback () {
      const IP = environment === 'DE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      // const IP = environment === 'TE' ? 'https://ivatest.terabits.cn' : 'https://xsiva.terabits.cn'
      // const type = localStorage.getItem('type')
      // if (type) {
      // window.open(`http://ldiva.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // } else {
      window.open(`${IP}/app/hotels?token=${this.$store.getters.token}`)
      // window.open(`http://ivatest.terabits.cn/app/areastatistics?token=${this.$store.getters.token}`)
      // }
    }
  }
};
</script>

<style scoped lang="scss">
.cityheader {
  // width: 100%;
  height: 80px;
  background: url("../../../../assets/city/header.png") no-repeat center bottom;
  // background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 18px 0 23px;
  margin-bottom: 3px;
  .left {
    display: flex;
    font-size: 16px;
    color: #ffffff;
    .iconfont {
      font-size: 24px;
      color: white;
      margin-right: 6px;
    }

    .weather {
      p:nth-child(2) {
        font-size: 12px;
        margin-top: 4px;
        span {
          margin-right: 17px;
        }
      }
    }
    .line {
      width: 1px;
      height: 27px;
      background: #0e6698;
      margin: 0 19px 0 3px;
    }
    .time {
      display: flex;
      > span {
        display: block;
        width: 28px;
        height: 27px;
        img {
          width: 100%;
        }
      }
      p {
        font-size: 16px;
        color: #00ffea;
      }
      .word {
        margin: 4px 15px 0 12px;
      }
      p:last-child {
        span {
          display: block;
          font-weight: bold;
        }
        span:last-child {
          font-size: 14px;
          font-weight: 400;
          margin-top: 11px;
          color: #ffffff;
        }
      }
      // p:nth-child(1) {
      //
      // }
    }
  }
  .center {
    // flex: 1;
    // text-align: center;
    // margin: 7px 599px 0 532px;
    // p {
    //   font-size: 36px;
    //   color: transparent;
    //   background-image: linear-gradient(135deg, #95fcf3, #7088c9, #fff);
    //   background-clip: text;
    //   font-weight: bold;
    //   background-image: -webkit-linear-gradient(135deg, #95fcf3, #7088c9, #fff);
    //   -webkit-background-clip: text;
    //   -webkit-text-fill-color: transparent;
    // }
    p {
      width: 275px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .right {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    // color: #29ddfe;
    > p {
      font-size: 12px;
      font-weight: 400;
      color: #fdfdfd;
      margin-right: 14px;
      height: 41px;
      line-height: 41px;
    }
    .goback {
      background: url("../../../../assets/city/back_bg.png") no-repeat;
      background-size: 100% 100%;
      width: 92px;
      height: 41px;
      line-height: 41px;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        display: block;
        background: url("../../../../assets/city/back_icon.png") no-repeat 100%;
        width: 9px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }
}
</style>

<template>
  <div class='area_map'>
    <ul class="top_map">
      <li>
        <div>
          <p>
            <img src="@/assets/area/hotel.png" alt="" srcset="">
          </p>
        </div>
        <countTo class="number" :startVal='0' :endVal='totalData.siteCount' :duration='2000' separator="" />
        <p class="name">酒店</p>
      </li>
      <li>
        <div>
          <p>
            <img src="@/assets/area/camera.png" alt="" srcset="">
          </p>
        </div>
        <countTo class="number" :startVal='0' :endVal='totalData.cameraCount' :duration='2000' separator="" />
        <p class="name">摄像头</p>
      </li>
      <li>
        <div>
          <p>
            <img src="@/assets/area/socket.png" alt="" srcset="">
          </p>
        </div>
        <countTo class="number" :startVal='0' :endVal='totalData.boardCount' :duration='2000' separator="" />
        <p class="name">插座</p>
      </li>
      <li>
        <div>
          <p>
            <img src="@/assets/area/disinfect.png" alt="" srcset="">
          </p>
        </div>
        <countTo class="number" :startVal='0' :endVal='totalData.roomCount' :duration='2000' separator="" />
        <p class="name">消毒间</p>
      </li>
      <li>
        <div>
          <p>
            <img src="@/assets/area/transducer.png" alt="" srcset="">
          </p>
        </div>
        <!-- <countTo class="number" :startVal='0' :endVal='totalData.runtime' :duration='2000' separator="" /> -->
        <countTo class="number" :startVal='0' :endVal='totalData.airCount' :duration='2000' separator="" />
        <p class="name">空气传感器</p>
      </li>
    </ul>
    <div class="geographical">
      <img ref="dot" hidden src="../../../../assets/area/map3.png">
      <div id="areamain"></div>
      <div class="areatooltip" v-if="Object.keys(scatterData).length > 0">
        <h3><i></i>{{scatterData.name}}</h3>
        <div class="total">
          <p><span>摄像头：</span>{{scatterData.cameraQuantity || 0}}</p>
          <p><span>插座：</span>{{scatterData.boardQuantity || 0}}</p>
          <p><span>消毒间：</span>{{scatterData.roomQuantity || 0}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountStatistic, getAreaList } from '@/api'
import countTo from 'vue-count-to';
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import { mapGetters } from 'vuex'
export default {
  components: { countTo },
  data () {
    return {
      totalData: {},
      echartsMap: null,
      map: null,
      district: null,
      geoJsonData: null,
      cityName: '中国',
      mapData: [],
      cityCode: null,
      areaList: [],
      mapMousemoveName: '',
      scatterData: {}
    };
  },
  created () {
    // this.init()
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    init () {
      getCountStatistic().then(res => {
        this.totalData = res.data
      })
      getAreaList().then(res => {
        this.areaList = res.data
        this.initMap(res.data[0], res.data[1].adcode)
      })
    },
    // 初始化地图
    initMap (val, parentCode) {
      let _this = this
      let map = this.map
      let district = this.district
      this.echartsMap = this.$echarts.init(document.getElementById('areamain'))
      this.echartsMap.on('click', this.echartsMapClick)
      this.echartsMap.on('mousemove', this.echartsMapMousemove)

      //监听地图滚动缩放事件
      // this.echartsMap.on("geoRoam", function () {
      //   var option = this.echartsMap.getOption();//获得option对象
      //   this.echartsMap.setOption(option, true);
      // })

      AMap.plugin('AMap.DistrictSearch', function () {
        district = new AMap.DistrictSearch({
          // 关键字对应的行政区级别，country表示国家
          // level: 'country',
          //  显示下级行政区级数，1表示返回下一级行政区
          subdistrict: 1,
          showbiz: false
        })
        if (val.adcode === '330621') {
          val.adcode = '330603'
        }
        // 搜索所有省/直辖市信息
        district.search(val.adcode, function (status, result) {
          // 查询成功时，result即为对应的行政区信息
          if (status == 'complete') {
            _this.getData(result.districtList[0], '', val.adcode, parentCode);
          }
        })
      })
      map = new AMap.Map('container', {
        resizeEnable: true,
        center: [116.30946, 39.937629],
        zoom: 3
      });
      this.map = map
      this.district = district
    },
    getData (data, level, adcode, parentCode) {
      var subList = data.districtList;
      let geoJsonData = this.geoJsonData
      let cityName = this.cityName
      let mapData = this.mapData
      if (subList) {
        var curlevel = subList[0].level;
        if (curlevel === 'street') {
          if (geoJsonData) {
            let mapJsonList = geoJsonData.features;
            let mapJson = {};
            for (let i in mapJsonList) {
              if (mapJsonList[i].properties.name == cityName) {
                mapJson.type = "FeatureCollection";
                mapJson.features = [].concat(mapJsonList[i]);
              }
            }
            mapData = []
            mapData.push({ name: cityName, value: Math.random() * 100, level: curlevel })
            this.mapData = mapData
            this.$nextTick(() => {
              this.loadMap(cityName, mapJson)
            })
          } else {
            this.loadMapData(parentCode)
            this.echartsMapClick({
              data: {
                name: data.name,
                level: level,
                cityCode: adcode
              }
            })
          }

          return;
        }
        mapData = [];
        for (var i = 0, l = subList.length; i < l; i++) {
          var name = subList[i].name;
          var cityCode = subList[i].adcode;
          mapData.push({
            name: name,
            value: Math.random() * 100,
            cityCode: cityCode,
            level: curlevel
          });
        }
        this.mapData = mapData
        this.loadMapData(adcode);
      }

    },
    loadMap (mapName, data) {
      const areaList = this.areaList
      const dotList = []
      areaList[0].siteList.map(el => {
        dotList.push({
          name: el.sitename,
          value: [el.longitude, el.latitude],
          phone: el.phone,
          roomQuantity: el.roomQuantity,
          cameraQuantity: el.cameraQuantity,
          boardQuantity: el.boardQuantity
        })
      })
      const cameraQuantity = areaList[0].siteList.reduce((a, b) => {
        return a + b.cameraQuantity
      }, 0)
      const boardQuantity = areaList[0].siteList.reduce((a, b) => {
        return a + b.boardQuantity
      }, 0)
      const roomQuantity = areaList[0].siteList.reduce((a, b) => {
        return a + b.roomQuantity
      }, 0)
      this.scatterData = {
        name: this.name,
        roomQuantity,
        cameraQuantity,
        boardQuantity
      }
      if (data) {
        this.$echarts.registerMap(mapName, data);
        var option = {
          tooltip: {
            show: false,
            trigger: 'item'
          },
          // toolbox: {
          //   show: true,
          //   feature: {
          //     dataZoom: {
          //       yAxisIndex: "none"
          //     },
          //     dataView: {
          //       readOnly: false
          //     },
          //     magicType: {
          //       type: ["line", "bar"]
          //     },
          //     restore: {},
          //     saveAsImage: {
          //       backgroundColor: 'none'
          //     }
          //   }
          // },
          /*backgroundColor: '#000f1e',*/
          geo: {
            map: mapName,
            aspectScale: 1.2,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: '100%',
            // scaleLimit: { //滚轮缩放的极限控制
            //   min: 1,
            //   max: 2
            // },
            // roam: true,
            zoom: 1.5, //当前视角的缩放比例
            itemStyle: {
              shadowColor: '#25394A',
              shadowOffsetX: 0,
              shadowOffsetY: 15,
              opacity: 0.6,
              areaColor: {
                image: this.$refs.dot,
                repeat: 'repeat'
              },
              emphasis: {
                areaColor: 'none',

              }
            },

          },
          series: [

            // 常规地图
            {
              type: 'map',
              mapType: mapName,
              // roam: 'scale',、
              aspectScale: 1.2,
              layoutCenter: ["50%", "50%"], //地图位置
              layoutSize: '100%',
              zoom: 1.5, //当前视角的缩放比例
              // scaleLimit: { //滚轮缩放的极限控制
              //   min: 1,
              //   max: 2
              // },
              // roam: true,
              itemStyle: {
                // normal: {
                areaColor: 'none',
                borderColor: '#F1F5F8',
                borderWidth: 2.5,
                opacity: 0.6,

                // },
                emphasis: {
                  areaColor: '#02102b',
                  label: {
                    color: "#fff"
                  },
                  opacity: 0.5,
                }
              },
              label: {
                show: true,
                color: 'white'
              },
              data: this.mapData,
            },
            // 区域散点图
            {
              type: 'scatter',
              coordinateSystem: 'geo',
              showAllSymbol: true,
              symbolSize: [24, 18],
              symbol: 'image://' + require('@/assets/area/map_icon.png'),
              data: dotList
            }
          ]
        }
        this.echartsMap.setOption(option)
      }
    },
    loadMapData (areaCode) {
      let map = this.map
      let geoJsonData = this.geoJsonData
      let cityName = this.cityName
      AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {

        //创建一个实例
        var districtExplorer = window.districtExplorer = new DistrictExplorer({
          eventSupport: true, //打开事件支持
          map: map
        });

        districtExplorer.loadAreaNode(areaCode, (error, areaNode) => {
          if (error) {
            return;
          }
          let mapJson = {};
          mapJson.type = "FeatureCollection";
          mapJson.features = areaNode.getSubFeatures();

          geoJsonData = mapJson;
          this.geoJsonData = geoJsonData
          this.$nextTick(() => {
            this.loadMap(cityName, mapJson);
          })
        })
      })
    },
    // 地图点击事件
    echartsMapClick (params) {//地图点击事件
      let cityName = this.cityName
      let cityCode = this.cityCode
      let district = this.district
      if (params.data.level == 'street') return;
      //清除地图上所有覆盖物
      cityName = params.data.name;
      cityCode = params.data.cityCode;
      district.setLevel(params.data.level); //行政区级别
      district.setExtensions('all');
      //行政区查询
      //按照adcode进行查询可以保证数据返回的唯一性
      district.search(cityCode, (status, result) => {
        if (status === 'complete') {
          this.getData(result.districtList[0], params.data.level, cityCode);
        }
      });
      this.cityName = cityName
      this.cityCode = cityCode
      this.district = district
    },
    echartsMapMousemove (params) {
      if (params.seriesType === 'scatter') {
        if (this.mapMousemoveName === params.name) {
          return
        } else {
          this.mapMousemoveName = params.name
          this.scatterData = params.data
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.area_map {
  // width: 100%;
  flex: 1;
  padding-right: 24px;
  .top_map {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > div {
        width: 126px;
        height: 87px;
        background: url("../../../../assets/area/center_bg.png") no-repeat;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // p {
        //   width: 50%;
        img {
          width: 50%;
          margin: 0 auto;
        }
        // }
      }
      > p,
      span {
        text-align: center;
        line-height: 1;
      }
      .number {
        font-size: 28px;
        font-weight: 800;
        color: #44f0ff;
        margin-top: -16px;
      }
      .name {
        font-size: 18px;
        font-weight: 700;
        color: #ffffff;
        margin-top: 8px;
      }
    }
  }
  .geographical {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    #areamain {
      width: 100%;
      height: 400px;
    }
    .areatooltip {
      // margin-left: 20px;
      // max-width: 30%;
      position: absolute;
      right: 5%;
      bottom: 5%;
      width: 240px;
      height: 169px;
      padding: 10px 16px 0 13px;
      box-sizing: border-box;
      background: url("../../../../assets/area/tooltip.png") no-repeat;
      background-size: 100% 100%;
      h3 {
        font-size: 16px;
        color: #ffffff;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        i {
          display: inline-block;
          width: 3px;
          height: 15px;
          background: #29ddfe;
          border-radius: 1px;
          margin-right: 7px;
        }
      }
      .total {
        margin-top: 15px;
        p {
          font-size: 16px;
          font-weight: bold;
          color: #44caff;
          margin-bottom: 26px;
          span {
            font-size: 12px;
            color: #ffffff;
            opacity: 0.8;
            font-weight: normal;
            display: inline-block;
            min-width: 80px;
            text-align: right;
            margin-right: 25px;
          }
        }
      }
    }
  }
}
</style>

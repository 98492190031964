<template>
  <div class='type_total'>
    <div class="swiper swiper_statistics" v-if="totalList.length>0">
      <div class="swiper-wrapper">
        <div v-for="(el, index) in totalList" class="swiper-slide" :key="index">
          <div class="label">
            <span class="name"> {{el.name}}</span>
            <span class="count"> {{el.count}}</span>
          </div>
          <div class="statistics">
            <div class="item" :style="{width: `${el.percent}%` }">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWeekRemind } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      totalList: [],
      maxNumber: 0
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getWeekRemind().then(res => {
        const arr = JSON.parse(JSON.stringify(res.data.type))
        arr.sort((a, b) => {
          return b.count - a.count
        })
        res.data.type.map(el => {
          el.percent = ((el.count / arr[0].count) * 100).toFixed(2)
        })
        this.totalList = res.data.type
        this.maxNumber = arr[0].count
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.totalList && this.totalList.length > 4) {
          new Swiper('.swiper_statistics', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.totalList && this.totalList.length <= 4) {
          new Swiper('.swiper_statistics', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.type_total {
  width: 544px;
  height: 306px;
  background: url("../../../../assets/area/left_bottom.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 41px 0 37px;
  box-sizing: border-box;
  .swiper_statistics {
    width: 100%;
    height: 216px;
    overflow: hidden;
    margin-top: 69px;
    .swiper-slide {
      width: 100%;
      height: 36px;
      margin-bottom: 24px;
      .label {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 6px;
        padding-left: 14px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .count {
          font-size: 16px;
        }
      }

      .statistics {
        position: relative;
        width: 95%;
        height: 16px;
        margin: 0 auto;
        background-color: transparent;
        background-color: rgba(98, 98, 98, 0.36);
        .item {
          position: absolute;
          bottom: 0;
          width: 50%;
          height: 100%;
          background-image: linear-gradient(
            90deg,
            rgba(139, 219, 249, 1),
            rgba(117, 218, 255, 0.8)
          );
          text-align: center;
          cursor: pointer;
        }

        .item::before {
          position: absolute;
          content: "";
          display: block;
          height: 100%;
          width: 8px;
          background-color: rgba(117, 218, 255, 1);
          border-radius: 50%;
          right: -4px;
          z-index: 1;
        }

        .item:after {
          position: absolute;
          content: "";
          display: block;
          height: 100%;
          width: 8px;
          border-radius: 50%;
          background-color: white;
          left: -4px;
        }

        // .itemPink {
        //   background-image: linear-gradient(
        //     90deg,
        //     rgba(247, 228, 250, 0.89),
        //     rgba(233, 165, 255, 0.8)
        //   );
        // }

        // .itemPink::before {
        //   background-color: rgba(233, 165, 255, 1);
        // }

        // .itemPink::after {
        //   background-color: rgba(247, 228, 250, 1);
        // }

        // .itemRed {
        //   background-image: linear-gradient(
        //     90deg,
        //     rgba(248, 102, 82, 0.938),
        //     rgba(244, 56, 6, 0.8)
        //   );
        // }

        // .itemRed::before {
        //   background-color: rgba(244, 56, 6, 1);
        // }

        // .itemRed::after {
        //   background-color: rgba(248, 102, 82, 1);
        // }

        // .itemOrg {
        //   background-image: linear-gradient(
        //     90deg,
        //     rgba(248, 153, 98, 0.938),
        //     rgba(245, 120, 2, 0.8)
        //   );
        // }

        // .itemOrg::before {
        //   background-color: rgba(245, 120, 2, 1);
        // }

        // .itemOrg::after {
        //   background-color: rgba(248, 153, 98, 1);
        // }

        // .itemRed .itemOrg
      }

      .statistics:before {
        position: absolute;
        content: "";
        display: block;
        height: 100%;
        width: 8px;
        border-radius: 50%;
        right: -4px;
        z-index: 1;
        background-color: rgba(49, 49, 49, 1);
      }

      .statistics:after {
        position: absolute;
        content: "";
        display: block;
        height: 100%;
        width: 14px;
        border-radius: 50%;
        left: -8px;
        top: 0;
        background-color: rgba(139, 219, 249, 1);
      }
    }
  }
}
</style>
